import React, { useEffect } from 'react'
import WOW from 'wowjs';
// import FooterImg from '../Images/footer.png'
import Facebook from '../Images/Vector (4).png'
import Insta from '../Images/ri_instagram-fill.png'
import X from '../Images/devicon_twitter (1).png'
import Youtube from '../Images/mingcute_youtube-fill.png'

const Footer = () => {
  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    wow.init();
  }, []);
  return (
    <>
      <div className="footer_wrapper " >
        {/* <div className="gapfooter"></div> */}

        <div className="footer" >

          <div className="footer_detail">

            <div  className="footer_detail-text">
            <h5>OFFICE ADDRESS</h5>
            <p>J's Innovation plaze km 5 Refinery road Expan. 
            Opp. St. Peter Anglican Church Expan </p>


                
            </div>

            <div className="footer_detail-text-tel">
            <h5 className=''>GET IN TOUCH</h5>
            <p className=''>+234 902 214 1201</p>
            <p className='footer_info'>09160559521</p>
            </div>
            
            <div className="flex_icon_carrier">
            <h5 className=''>GET US ON SOCIAL MEDIA</h5>
            <div className="flex_icon">
                  <div className="flex_iconCarrier1 wow animate__fadeInUp">
                    <img src={Facebook} alt="" />
                  </div>
                  <div className="flex_iconCarrier wow animate__fadeInUp">
                    <img src={Insta} alt="" />
                  </div>
                  <div className="flex_iconCarrier wow animate__fadeInUp">
                    <img src={Youtube} alt="" />
                  </div>
                  <div className="flex_iconCarrier1 wow animate__fadeInUp">
                    <img src={X} alt="" />
                  </div>
                </div>
            </div>

          </div>

          {/* <div className="footer_img">
            <div className="footer_img-carrier" >
              <img src={FooterImg} className='footerImg' alt="" />
            </div>
          </div> */}

          
        </div>


        <div className='copyright ' >
          <p>Copyright© 2023 Grejahmic Homes. All rights reserved.</p>
        </div>
      </div>
    </>
  )
}

export default Footer