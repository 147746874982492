import React from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import Vector from '../Images/Vector 90.png'
import SecondImg from '../Images/Mask group.png'
import ThirdImg from '../Images/Mask group3.png'
import fourthImg from '../Images/Mask group (2).png'
import fifthImg from '../Images/Mask group (200).png'
import { Link } from 'react-router-dom'


const Services = () => {
  return (
    <div className='home_class'>
      <div className='overlapNav'>
        <Header/>
          <section className='about_us-container'>
            <div className="container aboutContainerOne">
              <div className="about_text-header wow animate__fadeInUp" data-wow-duration="1.6s">
                <h1>We are a beacon of </h1><h1>trust and reliability.</h1>
                <p>At Grejahmic, we stand as a beacon of trust and reliability in the realm of real estate investment and acquisition. We are dedicated to offering you unparalleled expertise, unwavering commitment, and a steadfast resolve to achieve your investment goals.</p>
            </div>

            <div className="service_text-aboutImg">
              <img src={Vector} className='service_vector wow animate__fadeInLeft' data-wow-duration="1.8s" alt="avatar" />
              <p className=' wow animate__fadeInUp' data-wow-duration="1.8s">SERVICE</p>
            </div>
          </div>
         </section>
      </div>

      <div className='about_first-section'>
        <div className="about_firstImg wow animate__fadeInUp" data-wow-duration="1.8s">
          <img src={SecondImg} className='FirtstImg' alt="avatar" />
        </div>
      </div>

      <section>
        <div className="container">
          <div className="service_container">
            <div className="services_sell wow animate__fadeInUp" data-wow-duration="1.8s" data-wow-offset="150">
              <h3>LAND</h3>
              <p>Explore a diverse array of home acquisition opportunities tailored to meet your real estate requirements. Browse through our listings showcasing fully constructed residences and off-plan properties.</p>
              <div className="talkToUs_link">
                <Link to='/contact_us'className='talk_to_us'>Talk to us</Link>
              </div>
            </div>
            <div className="service_empty"></div>
          </div>
          <div className="services_sell_img wow animate__fadeInUp" data-wow-duration="1.8s" data-wow-offset="150">
            <img src={ThirdImg} className='service_sellImg' alt="avatar" />
          </div>
        </div>
      </section>


      <section>
        <div className="container">
          <div className="service_container">
            <div className="services_sell wow animate__fadeInUp" data-wow-duration="1.8s" data-wow-offset="150">
              <h3>HOUSE</h3>
              <p>Want to sell your real estate? You can leverage our network and platform to drive maximum visibility and attract the right value for your property within a reasonable time frame.</p>
              <div className="talkToUs_link">
                <Link to='/contact_us' className='talk_to_us'>Talk to us</Link>
              </div>
            </div>
            <div className="service_empty"></div>
          </div>
          <div className="services_sell_img wow animate__fadeInUp" data-wow-duration="1.8s" data-wow-offset="150">
            <img src={fourthImg} className='service_sellImg' alt="avatar" />
          </div>
        </div>
      </section>


      <section>
        <div className="container">
          <div className="service_container">
            <div className="services_sell wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
              <h3>PROPERTY MANAGEMENT</h3>
              <p>Seeking guidance to make informed real estate investment choices? Our team is ready to address your queries and offer expert recommendations anchored in sound economic analysis, that will help you arrive at your desired outcome.</p>
              <div className="talkToUs_link">
                <Link to='/contact_us' className='talk_to_us'>Complete the request form</Link>
              </div>
            </div>
            <div className="service_empty"></div>
          </div>
          <div className="services_sell_img wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <img src={fifthImg} className='service_sellImg' alt="avatar" />
          </div>
        </div>
      </section>






      <Footer/>
    </div>
  )
}

export default Services