import React from 'react'
import Vector from '../Images/Vector 90.png'
import Footer from '../component/footer'
import Header from '../component/header'
import FirtstImg from '../Images/Group 32.png'
import SecondImg from '../Images/Mask group.png'

const AboutUs = () => {

  return (
    <div className='home_class'>
      <Header />
      <section className='about_us-container'>
        <div className="container aboutContainerOne">
          <div className="about_text-header">
            <h1>Simplifying Your </h1> <h1>Real Estate Journey</h1>
            <p className="wow animate__fadeInUp mt-2" data-wow-duration="1.9s">At Grejahmic Homes, we believe that buying and selling property should be an exciting journey, not a stressful one. That's why we focus on building long-term relationships, driven by transparency, integrity, and professionalism. With us, you can be confident that you're in good hands.</p>
          </div>

          <div className="service_text-aboutImg " >
            <img src={Vector} className='service_vector wow animate__fadeInLeft' data-wow-duration="1.8s" alt="avatar" />
            <p className=' wow animate__fadeInUp' data-wow-duration="1.8s">ABOUT US</p>
          </div>

        </div>
      </section>


      <div className='about_first-section wow animate__fadeInUp' data-wow-duration="1.4s" data-wow-offset="80" >
        <div className="about_firstImg">
          <img src={SecondImg} className='FirtstImg' alt="avatar" />
        </div>
      </div>

      <section>
        <div className="container">
          <div className="about_valueOffer wow animate__fadeInUp" data-wow-offset="150" data-wow-duration="1.5s">
            <p className="whyChoose">Why Choose <br/>Grejahmic Homes?</p>
          </div>
        </div>
      </section>




      <section className='container'>
        <div className=" alignItems">
            <div className="Image_position_carrier wow animate__fadeInUp"  data-wow-duration="2s">
              <div className="img_positioning">
                <img src={SecondImg} className='imgposition' alt="Avatar" />
              </div>
            </div>

            <div className="commitmentCarrier">
              <div className='commitmentTextCarrier wow animate__fadeInUp'  data-wow-duration="2s" >
                <h3>Expert Guidance and Market Insights</h3>
                <p>A top-tier real estate firm offers clients access to experienced professionals with deep knowledge of the market. This expertise ensures that clients are provided with valuable insights on market trends, property valuations, and investment opportunities. With personalized advice, buyers and investors can make informed decisions that align with their financial goals and risk tolerance.</p>
              </div>
            </div>
          </div>
      </section>



      <section className='container'>
        <div className="align_items">

            <div className="commitmentCarrier">
              <div className='commitmentTextCarrier wow animate__fadeInUp pt-4' data-wow-duration="2s" >
                <h3>Comprehensive Property Management Services</h3>
                <p>Beyond just buying or selling properties, a quality real estate firm provides end-to-end property management services. From tenant sourcing and lease management to maintenance and repairs, these services help clients maintain the value of their investment while reducing the hassle of day-to-day property management. This ensures a smooth, worry-free ownership experience for both residential and commercial property owners.</p>
              </div>
            </div>


            <div className="Image_position_carrier wow animate__fadeInUp"  data-wow-duration="2s">
              <div className="img_positioning">
                <img src={SecondImg} className='imgposition' alt="Avatar" />
              </div>
            </div>


        </div>
      </section>
      

      <section className='container'>
        <div className="alignItems">

            <div className="Image_position_carrier wow animate__fadeInUp"  data-wow-duration="2s">
              <div className="img_positioning">
                <img src={SecondImg} className='imgposition' alt="Avatar" />
              </div>
            </div>

            <div className="commitmentCarrier">
              <div className='commitmentTextCarrier wow animate__fadeInUp' data-wow-duration="2s" >
                <h3>Sustainable and Modern Developments</h3>
                <p>In today’s real estate market, sustainability and innovation are key drivers of long-term property value. A forward-thinking real estate firm focuses on developing or sourcing properties that incorporate eco-friendly designs, energy-efficient technologies, and modern amenities. These features not only reduce operational costs for property owners but also attract environmentally conscious buyers and tenants, enhancing marketability and future value.</p>
              </div>
            </div>
          </div>
      </section>











      <Footer />
    </div>
  )
}

export default AboutUs