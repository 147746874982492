import React from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import ThirdImg from '../Images/Mask group3.png'
import { MdCheckCircle, MdCircle } from 'react-icons/md'

const propertyDetails = () => {
  return (
    <div className='home_class'>
      <div className='overlapNav'>
        <Header/>
          <section className='about_us-container'>
            <div className="container aboutContainerOne">
              <div className="about_text-header">
                <h1 className="wow animate__fadeInUp" data-wow-duration="1.8s">Premium Estate</h1>
                <p className=" wow animate__fadeInUp" data-wow-duration="3s">Buying affordable land in a fast developing area like premium estate is what every smart investor is always looking for. Are you looking for a land that's free from government acquisition with flexible payment plans? Our new premium estate is the best option for you.</p>
            </div>

            {/* <div className="service_text-aboutImg " >
              <img src={Vector} className='service_vector wow animate__fadeInLeft' data-wow-duration="3s" alt="avatar" />
              <p className='wow animate__fadeInUp' data-wow-duration="3.9s">SERVICE</p>
            </div> */}
          </div>
         </section>
      </div>

      <div className='about_first-section'>
        <div className="about_firstImg wow animate__fadeInUp" data-wow-duration="1.8s">
        <img src={ThirdImg} className='service_sellImg' alt="avatar" />
        </div>
      </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>All our properties are totally free from government acquisition. Get allocation immediately after the payment. Flexible payment plans available for 3 months and 6 months. Everything you need is in Premium Estate Buy now before the price increase.</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>This estate is tailored to meet your highest standards, with excellently designed luxury homes, that will bring you and your family comfort, peace and harmony.</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>All our properties are totally free from government acquisition. Get allocation immediately after the payment. Flexible payment plans available for 3 months and 6 months. Everything you need is in Premium Estate Buy now before the price increase</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>Currently selling for 1.5 million naira per plot 465sqm and installment plan available.</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <h5>TITLE:</h5><p>Freehold, Registered Survey & Deed of conveyance</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <h5>ESTATE FEATURES:</h5>
            <div className="estate_detail">
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Perimeter fencing</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Gate house</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Good Road Network</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Drainage system</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Serene Environment</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Security</p></div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Affordability</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div> <p>High Returns on Investment</p> </div> </div>
              
              <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div> <p>And lots more</p> </div> </div>
            </div>
          </div>
        </div>

      <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>For site inspection and further inquiries, Call <a href="tel">+234 902 214 1201</a> or <a href="tel">+2349160559521</a></p>
          </div>
        </div>




        <Footer/>
    </div>
  )
}

export default propertyDetails