import React from 'react'
import FirtstImg from '../Images/Group 32.png'
import SecondImg from '../Images/Screenshot 2024-10-11 at 06.07.48 1.png'
import Avatar1 from '../Images/Mask group (201).png'
import Avatar from '../Images/Mask group (9).png'
import Header from '../component/header'
import Footer from '../component/footer'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { FaArrowRightLong } from 'react-icons/fa6'

const Home = () => {
  return (
      <div className='home_class'>
        <div className="home_navWrapper">
          <Header/>
            <section className='home_section'>
             <div className="container">
              <div className="home_trusted-partner" >
                <h1 className="  wow animate__fadeInUp" data-wow-duration="1.5s">Your Trusted Partner in <br/> Real Estate</h1>
                <p className=" para1 wow animate__fadeInUp" data-wow-duration="3s">Grejahmic homes is the fast growing real estate company with a sole interest in </p>
                <p className=" wow animate__fadeInUp" data-wow-duration="3.5s"> sales/purchase of land and developing of property.</p>
              </div>
              <div className="btn_flex">
                <a href="/about" className="yellow_btn wow animate__fadeInUp" data-wow-duration="4.5s">
                  Learn More
                </a>

                <a href="/properties" className="white_btn wow animate__fadeInUp" data-wow-duration="6s">
                  Get Started
                </a>
              </div>
            </div>
            <div className="container">
                <div className="home_firstImgCarrier wow animate__fadeInUp" data-wow-duration="2s">
                  <img src={FirtstImg} className='FirtstImg' alt="avatar" />
                </div>
            </div>
          </section>

        </div>

          <section>
            <div className="container">
              <div className="valueOffer wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
                <div className='card_flex-container'>
                  <div className="peace_img">
                    <img src={SecondImg} alt="Avatar" className='peace_Img_carrier' />
                  </div>

                  <div className="peace_text">
                    <div className="peace_text_detail">
                      <h2>We sell peace of mind</h2>
                      <p>Grejahmic Homes was founded on the principle of dependability. We understand the significance of trust in every human relationship, and the real estate industry desires it most.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section>
            <div className="popular">
              <div className="popular_text">
                <div className="popular_header">
                  <h2>Our Popular Properties</h2>
                </div>
                <div className="popular_seeMore">
                  <Link className='view_all' to='properties'><p>View All</p> <FaArrowRightLong className='fafa'/></Link>
                </div>
              </div>

              <div className='property_section'>
                <div className='card_flex-container'>
                  <Card className='cardCarrier wow animate__fadeInUp'  data-wow-duration="2s" data-wow-offset="150">
                    <Card.Img variant='top' className='Img_height' src={Avatar} />
                    <Card.Body >
                      <div className="Btn_flex-container">
                        <div className="name_carrier">
                          <Card.Title className='primeText wow animate__fadeInUp'  data-wow-duration="2.5s">Prime Estate</Card.Title>
                          <Card.Text className='cardBody wow animate__fadeInUp'  data-wow-duration="3s">Warri, Delta State</Card.Text>
                        </div>
                        <div className="amt_carrier">
                          <p className='naira wow animate__fadeInUp'  data-wow-duration="3.5s">#1.2M</p>
                        </div>
                      </div>
                      <div className="viewalllinkdiv">
                       <Link to='/prime_estate_detail' className='viewall wow animate__fadeInUp'  data-wow-duration="4s"> View All</Link>
                      </div>
                    </Card.Body>
                  </Card>

                  <Card className='cardCarrier wow animate__fadeInUp'  data-wow-duration="3.5s" data-wow-offset="150">
                    <Card.Img variant='top' className='Img_height' src={Avatar1} />
                    <Card.Body >
                      <div className="Btn_flex-container">
                        <div className="name_carrier">
                          <Card.Title className='primeText wow animate__fadeInUp'  data-wow-duration="3.8s">Premium Estate</Card.Title>
                          <Card.Text className='cardBody wow animate__fadeInUp'  data-wow-duration="4.1s">Warri, Delta State</Card.Text>
                        </div>
                        <div className="amt_carrier">
                          <p className='naira wow animate__fadeInUp'  data-wow-duration="4.s">#1.4M</p>4
                        </div>
                      </div>
                      <div className="viewalllinkdiv">
                       <Link to='property_details' className='viewall wow animate__fadeInUp'  data-wow-duration="5s"> View All</Link>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </section> */}

          <section className='simplifyCarrier'>
            <div className='simplify'>
            <div className="simplify_title">
              <h3 className='weText wow animate__fadeInUp' data-wow-duration="1.5s">We simplify this process for you. </h3>
            </div>
              <div className='home_flex-container'>
                <div className="choose_property wow animate__fadeInUp" data-wow-duration="2.2s">
                  <div className="choose_property-text">
                    <h4 className='choose_property'>Integrity and Trust</h4>
                    <p>We believe in building trust through honesty, transparency, and open communication. Your happiness is our priority.</p>
                  </div>
                </div>

                <div className="choose_property wow animate__fadeInUp" data-wow-duration="4.4s">
                  <div className="choose_property-text">
                    <h4 className='choose_property'>Tailored Solutions</h4>
                    <p>Every client is unique, and so is our approach. We deliver solutions that are specifically designed to meet your needs.</p>
                  </div>
                </div>

                <div className="choose_property wow animate__fadeInUp" data-wow-duration="6.5s">
                  <div className="choose_property-text">
                    <h4 className='choose_property'>Market Expertise</h4>
                    <p> With a deep understanding of the real estate market, we provide you with the latest insights and guidance.</p>
                  </div>
                </div>

              </div>  
            </div>
          </section>

          <section>
            <div className="container">
              <div className="perfect_product wow animate__fadeInUp" data-wow-duration="2s">
                <h2>Experience exceptional services</h2>
              </div>
            </div>
            <section className='buy_sell_invest'>
                <div className="home_flex-container">
                  <div className="home-buy_container wow animate__fadeInUp" data-wow-duration="2s">
                    <div className="home_buy">
                      <div className="home_buy-text">
                        <h4>BUY</h4>
                        <p>Explore a diverse array of home 
                        acquisition opportunities.</p>
                      </div>
                    </div>
                  </div>
                  <div className="home-sell_container wow animate__fadeInUp" data-wow-duration="3.5s">
                    <div className="home_sell">
                      <div className="home_sell-text">
                        <h4>SELL</h4>
                        <p>Leverage our network and platform to drive maximum visibility.</p>
                      </div>
                    </div>
                  </div>
                  <div className="home-invest_container wow animate__fadeInUp"data-wow-duration="5s">
                    <div className="home_invest">
                      <div className="home_invest-text">
                        <h4>INVEST</h4>
                        <p>Investing in secure and sustainable properties that promise lucrative returns.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
          </section>



        <Footer/>
      </div>
  )
}

export default Home