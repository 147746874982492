import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Logo from '../Images/Group 48.png'
import Navbar from 'react-bootstrap/Navbar';

const Navheader = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="wow animate__fadeInDown" data-wow-duration="1.5s">
        
     <div className='container nav_container'>
      <Navbar.Brand href="/">
           <div className='nav_logo1'>
            <img src={Logo} alt="Navbar Logo" />
          </div>
      </Navbar.Brand>
      <Navbar.Toggle className='Toggle' aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mx-auto ">
          <Nav.Link href="/about" className='navtext'>About Us</Nav.Link>
          <Nav.Link href="/properties" className='navtext'>Properties</Nav.Link>
          <Nav.Link href="/" className='nav_logo'><img src={Logo} alt="Navbar Logo" /></Nav.Link>
          <Nav.Link href="/services" className='navtext'>Services</Nav.Link>
          <Nav.Link href="/contact_us" className='navtext'>Contact Us</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </div>
  </Navbar>
  )
}

export default Navheader