import React from 'react'
import ThirdImg from '../Images/Mask group3.png'
import Header from '../component/header'
import Footer from '../component/footer'
import { GoDotFill } from 'react-icons/go'
import { MdCheckCircle, MdCircle } from 'react-icons/md'

const SpringDetail = () => {
  return (
    <div className='home_class'>

        <div className='overlapNav'>
        <Header/>
          <section className='about_us-container'>
            <div className="container aboutContainerOne">
              <div className="about_text-header">
                <h1 className="wow animate__fadeInUp" data-wow-duration="1.8s">Spring View Estate</h1>
                <p className=" wow animate__fadeInUp" data-wow-duration="3s">A very lucrative property investment opportunity in a strategic location in ughelli express road, well positioned for residential development and good return of investment.</p>
            </div>
          </div>
         </section>
        </div>

        <div className="springBanner">
            <img src={ThirdImg} alt="img" />
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>All our properties are totally free from government acquisition. Get allocation immediately after the payment. Flexible payment plans available for 3 months and 6 months. Everything you need is in Spring View Estate Buy now before the price increase.</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>This estate is tailored to meet your highest standards, with excellently designed luxury homes, that will bring you and your family comfort, peace and harmony.</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <p>Currently selling for 2million naira per plot 465sqm and installment plan available.</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            <h5>Title:</h5><p>Freehold, Registered Survey & Deed of conveyance</p>
          </div>
        </div>

        <div className="container">
          <div className="propertyDetail_text wow animate__fadeInUp" data-wow-duration="2s" data-wow-offset="150">
            
            <div className="estateCarrier">
                <h5>Key Features:</h5>
                <div className="estate_detail">
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Location: Oha, near Delta State University of Science and Technology</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Plot Size: 465SQM</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>100% Dry Land</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Table Top Land</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Well-Designed Layout</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Good Road Network</p></div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Neighborhood: University of Delta State University of Science and Technology</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div> <p>5-minute drive from Osubi Airport</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div> <p>Proposed Police Academy nearby</p> </div> </div>
                </div>
            </div>
            
            <div className="estateCarrier">
                <h5>Why You Should Invest</h5>
                <div className="estate_detail">
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Strategic Location for Rental Income</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>High Appreciation Value</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Suitable for Residential or Commercial Development</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Secure and Serene Environment</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Proximity to Educational Institutions</p> </div> </div>
                </div>
            </div>

            <div className="estateCarrier">
                <h5>Payment Plan:</h5>
                <div className="estate_detail_pay">
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Outright Payment: 1.5M</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Flexible Payment Options Available (Contact us for details)</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Pre launch price Residential 1.5M</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Pre launch price commercial 2m</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Actual Price Residential 2.5m</p> </div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Security</p></div> </div>
                
                <div className='estate_pargraph'> <div className='mdCircle'><MdCheckCircle /></div> <div><p>Actual Price commercial 3m</p> </div> </div>
                </div>
            </div>

          </div>
        </div>



        {/* <div className="container">
            <div className="springFlex">
                <div className="springBanner">
                    <img src={SpringImg} alt="img" />
                </div>

                <div className="springText">
                    <div className="spring_text_carrier">
                        <h2 className='S_view_estate'>Spring View Estate
                        </h2>
                        <p>Limited Time Offer: Pre-Launch Price 1.5M (Actual Price 2.5M)</p>
                        <p>Secure your future with a prime plot in Delta State's most sought-after location!</p>
                        <h4 className='keypay'>Key Features:</h4>
                        <div className="keyfeatures">
                        <div className="dotCircle">
                            <MdCircle /><p>Plot Size: 465SQM</p>
                            </div>
                            <div className="dotCircle">
                            <MdCircle /><p>Plot Size: 465SQM</p>
                            </div>
                            <div className="dotCircle">
                            <MdCircle /><p>Plot Size: 465SQM</p>
                            </div>
                            <div className="dotCircle">
                            <MdCircle /><p>Plot Size: 465SQM</p>
                            </div>
                            <div className="dotCircle">
                            <MdCircle /><p>Plot Size: 465SQM</p>
                            </div>
                            <div className="dotCircle">
                            <MdCircle /><p>Plot Size: 465SQM</p>
                            </div>
                            <div className="dotCircle">
                            <MdCircle /><p>Plot Size: 465SQM</p>
                            </div>
                        </div>
                        <h4 className='keypay'>Payment Plan:</h4>
                        <div className="keyfeatures">
                            <p>Outright Payment: 1.5M</p>
                            <p>Flexible Payment Options Available (Contact us for details)</p>
                            <p>Pre launch price Residential 1.5M</p>
                            <p>Pre launch price commercial 2m
                            </p>
                            <p>Actual Price Residential 2.5m
                            </p>
                            <p>Actual Price commercial 3m</p>
                        </div>

                    </div>
                </div>
            </div>
        </div> */}

        <Footer/>
    </div>
  )
}

export default SpringDetail