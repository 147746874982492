import React from 'react'
import Header from '../component/header'
import Footer from '../component/footer'
import Avater from '../Images/Premuim.jpeg'
import Vector from '../Images/Vector 90.png'
import FirstImg from '../Images/Prime.jpeg'
import SpringImg from '../Images/SpringView.png'
import { Link } from 'react-router-dom'

const Properties = () => {
  return (
    <div className='home_class'>
      <div className='overlapNav'>
        <Header />
        <section className='about_us-container'>
          <div className="container aboutContainerOne">
            <div className="about_text-header wow animate__fadeInUp" data-wow-duration="1.6s">
              <h1>Explore Our <br />Premium Properties</h1>
              <p className='mt-2'>Our premium properties are strategically located in prime areas, offering an excellent foundation for residential development.<br className='d-sm-none'/> Each property is carefully selected and positioned to maximize growth potential, ensuring a high return on investment for our clients</p>
            </div>

            <div className="service_text-aboutImg">
              <img src={Vector} className='service_vector wow animate__fadeInLeft' data-wow-duration="1.8s" alt="avatar" />
              {/* <p className=' wow animate__fadeInUp' data-wow-duration="2.8s" data-wow-offset="150">PROPERTIES</p> */}
            </div>
          </div>
        </section>
      </div>

      <section className="container">
      <div className="row">

       <Link to='/premium_estate_detail' className="col-md-6">
          <div className="estate_wrapper mx-2">
            <div className="avatarCarrier wow animate__fadeInUp" data-wow-duration="2.8s">
              <img src={Avater} className='avatar' alt="avatar" />
            </div>

              <div className="estate_title wow animate__fadeInUp" data-wow-duration="1.8s" data-wow-offset="150">
                <div className="estate_title_header">
                  <h3>Premium Esate </h3>
                  <h3 className='amount'> ₦2m</h3>
                </div>
                <p>PLOT SIZES: 465SQM</p>
              </div>

            <div className="estate_detail wow animate__fadeInUp" data-wow-duration="1.5s" data-wow-offset="150">
              <p>Buying affordable land in a fast developing area like premium estate is what every smart investor is always looking for. Are you looking for a land that's free from government acquisition with flexible payment plans? Our new premium estate is the best option for you.</p>
            </div>
          </div>
        </Link>


        <Link to='/prime_estate_detail' className="col-md-6">
          <div className="estate_wrapper mx-2">
            <div className="avatarCarrier estate_title wow animate__fadeInUp" data-wow-duration="1.5s" data-wow-offset="150">
              <img src={FirstImg} className='avatar' alt="avatar" />
            </div>

              <div className="estate_title wow animate__fadeInUp" data-wow-duration="2.9s" data-wow-offset="180">
                <div className="estate_title_header">
                  <h3>Prime Estate</h3>
                  <h3 className='amount'> ₦2m </h3>
                </div>
                <p>PLOT SIZES: 465SQM</p>
              </div>

            <div className="estate_detail wow animate__fadeInUp" data-wow-duration="1.9s" data-wow-offset="150">
              <p>A very lucrative property investment opportunity in a strategic location in ughelli express road, well positioned for residential development and good return of investment.</p>
            </div>
          </div>
        </Link>

        </div>
      </section>

      <div className="container">
      <div className="row">

        <Link to='/spring_view_estate_detail' className="col-md-6">
          <div className="estate_wrapper mx-2">
            <div className="avatarCarrier estate_title wow animate__fadeInUp" data-wow-duration="1.5s" data-wow-offset="150">
              <img src={SpringImg} className='avatar' alt="avatar" />
            </div>

              <div className="estate_title wow animate__fadeInUp" data-wow-duration="2.9s" data-wow-offset="180">
                <div className="estate_title_header">
                  <h3>Spring-View Estate</h3>
                  <h3 className='amount'> ₦2m </h3>
                </div>
                <p>PLOT SIZES: 465SQM</p>
              </div>

            <div className="estate_detail wow animate__fadeInUp" data-wow-duration="1.9s" data-wow-offset="150">
              <p>A very lucrative property investment opportunity in a strategic location in ughelli express road, well positioned for residential development and good return of investment.</p>
            </div>
          </div>
        </Link>

        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Properties