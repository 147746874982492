import React from 'react'
import {Link} from 'react-router-dom'
import BasicExample from '../component/nav'

const ErrorPage = () => {
  return (
    <>
    <BasicExample/>
    <section className='error-page'>
      <div className="error-center">
        <Link to="/" className='btn primary'>Go Back Home </Link>
        <h2>Page Not Found</h2>
      </div>
    </section>
    </>
  )
}

export default ErrorPage